

.hero-container {
  /* background-color: '#547291'; */
  background-color: #181818;
  height: 78.5vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  position: relative;
  z-index: 100;
  
}

.hero-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}

.hero-container > p {
  margin-top: 0px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
  margin-top: -100px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

.logo {
  width: 200px;
  position: relative;
}

.bottom{
  width: 400px;
  position: relative;
}

.promo {
  font-size: 30px;
  color: #fff;
  font-family: 'bold';
  margin-top: -230px;
  margin-bottom: 50px;
  position: relative;
  
}

.hero-container-2 {
  /* background-color: '#547291'; */
  margin-top: -360px;
  position: relative;
  z-index: -1;
 
  
}




@media screen and (max-width: 960px) {
  
  .promo {
    font-size: 30px;
    color: #fff;
    font-family: 'bold';
    margin-top: -230px;
    margin-bottom: 50px;
    position: relative;
    
  }

  .bottom{
    width: 200px;
    position: relative;
  }
}

@media screen and (max-width: 768px) {

  .bottom{
    /* width: 265px; */
    position: relative;
  }

  .bDiv{
    position: absolute;
    bottom: -4.5px;
  }

  .hero-container-2 {
    /* background-color: '#547291'; */
    margin-top: -490px;
    position: relative;
    z-index: -1;
   
    
  }
  .hero-container {
    /* background-color: '#547291'; */
    height: 82.5vh;
  }
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }


.logo {
  /* width: 120px; */
  position: relative;
}
.promo {
  font-size: 22px;
  color: #fff;
  font-family: 'bold';
  margin-top: -100px;
  margin-bottom: 10px;
  position: relative;
  
}


  
}
