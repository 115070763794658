@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);

@font-face {
  font-family: "Bold";   /*Can be any text*/
  src: local("CalibreBold"),
    url(/static/media/CalibreBold.6feab952.otf) format("opentype");
}

@font-face {
  font-family: "Medium";   /*Can be any text*/
  src: local("CalibreMedium"),
    url(/static/media/CalibreMedium.75ad32e4.otf) format("opentype");
}

@font-face {
  font-family: "Regular";   /*Can be any text*/
  src: local("CalibreRegular"),
    url(/static/media/CalibreRegular.93957dd4.otf) format("opentype");
}

@font-face {
  font-family: "Semibold";   /*Can be any text*/
  src: local("CalibreSemibold"),
    url(/static/media/CalibreSemibold.85d899ed.otf) format("opentype");
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: 'Bold'; */
  /* background-color: #f0f0f0; */
}

body {
  background-color: #242424; /* Replace with your desired background color */
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url('/images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('/images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('/images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}



.hero-container {
  /* background-color: '#547291'; */
  background-color: #181818;
  height: 78.5vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  position: relative;
  z-index: 100;
  
}

.hero-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}

.hero-container > p {
  margin-top: 0px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
  margin-top: -100px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

.logo {
  width: 200px;
  position: relative;
}

.bottom{
  width: 400px;
  position: relative;
}

.promo {
  font-size: 30px;
  color: #fff;
  font-family: 'bold';
  margin-top: -230px;
  margin-bottom: 50px;
  position: relative;
  
}

.hero-container-2 {
  /* background-color: '#547291'; */
  margin-top: -360px;
  position: relative;
  z-index: -1;
 
  
}




@media screen and (max-width: 960px) {
  
  .promo {
    font-size: 30px;
    color: #fff;
    font-family: 'bold';
    margin-top: -230px;
    margin-bottom: 50px;
    position: relative;
    
  }

  .bottom{
    width: 200px;
    position: relative;
  }
}

@media screen and (max-width: 768px) {

  .bottom{
    /* width: 265px; */
    position: relative;
  }

  .bDiv{
    position: absolute;
    bottom: -4.5px;
  }

  .hero-container-2 {
    /* background-color: '#547291'; */
    margin-top: -490px;
    position: relative;
    z-index: -1;
   
    
  }
  .hero-container {
    /* background-color: '#547291'; */
    height: 82.5vh;
  }
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }


.logo {
  /* width: 120px; */
  position: relative;
}
.promo {
  font-size: 20px;
  color: #fff;
  font-family: 'bold';
  margin-top: -100px;
  margin-bottom: 10px;
  position: relative;
  
}


  
}

.footer-container {
  background-color: #242424;
  padding: 1rem 0 0rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

.footer-subscription > p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::-webkit-input-placeholder {
  color: #b1b1b1;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #929292;
  font-size: 24px;
}

.social-icon-link:hover {
  color: white;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100%; */
  /* max-width: 1000px; */
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.social-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  margin-bottom: 2rem;
  
}

.social-links-design {
  color: white;
  text-decoration: none;
  font-family: 'medium';
  font-size: 1rem;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #929292;
  margin-bottom: 16px;
  font-family: 'Regular';
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
    
  }
}

@media screen and (max-width: 768px) {
  .social-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 320px;
    margin-bottom: 1.2rem;
    
  }
  .social-links-design {
    color: white;
    text-decoration: none;
    font-family: 'medium';
    font-size: .77rem;
  }
  
}



.hero-container {
  /* background-color: '#547291'; */
  background-color: #181818;
  height: 78.5vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  position: relative;
  z-index: 100;
  
}

.hero-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}

.hero-container > p {
  margin-top: 0px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
  margin-top: -100px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

.logo {
  width: 200px;
  position: relative;
}

.bottom{
  width: 400px;
  position: relative;
}

.promo {
  font-size: 30px;
  color: #fff;
  font-family: 'bold';
  margin-top: -230px;
  margin-bottom: 50px;
  position: relative;
  
}

.hero-container-2 {
  /* background-color: '#547291'; */
  margin-top: -360px;
  position: relative;
  z-index: -1;
 
  
}




@media screen and (max-width: 960px) {
  
  .promo {
    font-size: 30px;
    color: #fff;
    font-family: 'bold';
    margin-top: -230px;
    margin-bottom: 50px;
    position: relative;
    
  }

  .bottom{
    width: 200px;
    position: relative;
  }
}

@media screen and (max-width: 768px) {

  .bottom{
    /* width: 265px; */
    position: relative;
  }

  .bDiv{
    position: absolute;
    bottom: -4.5px;
  }

  .hero-container-2 {
    /* background-color: '#547291'; */
    margin-top: -490px;
    position: relative;
    z-index: -1;
   
    
  }
  .hero-container {
    /* background-color: '#547291'; */
    height: 82.5vh;
  }
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }


.logo {
  /* width: 120px; */
  position: relative;
}
.promo {
  font-size: 20px;
  color: #fff;
  font-family: 'bold';
  margin-top: -100px;
  margin-bottom: 10px;
  position: relative;
  
}

  
}

.footer-container {
  background-color: #242424;
  padding: 1rem 0 0rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

.footer-subscription > p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::-webkit-input-placeholder {
  color: #b1b1b1;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #929292;
  font-size: 24px;
}

.social-icon-link:hover {
  color: white;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100%; */
  /* max-width: 1000px; */
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

/* This control the spacing between link */

.social-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  margin-bottom: 2rem;
  
}

.social-links-design {
  color: white;
  text-decoration: none;
  font-family: 'medium';
  font-size: 1rem;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #929292;
  margin-bottom: 16px;
  font-family: 'Regular';
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
    
  }
}

@media screen and (max-width: 768px) {
  .social-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 310px;
    margin-bottom: 1.2rem;
    
  }
  .social-links-design {
    color: white;
    text-decoration: none;
    font-family: 'medium';
    font-size: .9rem;
  }
  
}



.hero-container {
  /* background-color: '#547291'; */
  background-color: #181818;
  height: 78.5vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  position: relative;
  z-index: 100;
  
}

.hero-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}

.hero-container > p {
  margin-top: 0px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
  margin-top: -100px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

.logo {
  width: 200px;
  position: relative;
}

.bottom{
  width: 400px;
  position: relative;
}

.promo {
  font-size: 30px;
  color: #fff;
  font-family: 'bold';
  margin-top: -230px;
  margin-bottom: 50px;
  position: relative;
  
}

.hero-container-2 {
  /* background-color: '#547291'; */
  margin-top: -360px;
  position: relative;
  z-index: -1;
 
  
}




@media screen and (max-width: 960px) {
  
  .promo {
    font-size: 30px;
    color: #fff;
    font-family: 'bold';
    margin-top: -230px;
    margin-bottom: 50px;
    position: relative;
    
  }

  .bottom{
    width: 200px;
    position: relative;
  }
}

@media screen and (max-width: 768px) {

  .bottom{
    /* width: 265px; */
    position: relative;
  }

  .bDiv{
    position: absolute;
    bottom: -4.5px;
  }

  .hero-container-2 {
    /* background-color: '#547291'; */
    margin-top: -490px;
    position: relative;
    z-index: -1;
   
    
  }
  .hero-container {
    /* background-color: '#547291'; */
    height: 82.5vh;
  }
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }


.logo {
  /* width: 120px; */
  position: relative;
}
.promo {
  font-size: 22px;
  color: #fff;
  font-family: 'bold';
  margin-top: -100px;
  margin-bottom: 10px;
  position: relative;
  
}


  
}

.footer-container {
  background-color: #242424;
  padding: 1rem 0 0rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

.footer-subscription > p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::-webkit-input-placeholder {
  color: #b1b1b1;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #929292;
  font-size: 24px;
}

.social-icon-link:hover {
  color: white;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100%; */
  /* max-width: 1000px; */
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.social-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  margin-bottom: 2rem;
  
}

.social-links-design {
  color: white;
  text-decoration: none;
  font-family: 'medium';
  font-size: 1rem;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #929292;
  margin-bottom: 16px;
  font-family: 'Regular';
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
    
  }
}

@media screen and (max-width: 768px) {
  .social-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 320px;
    margin-bottom: 1.2rem;
    
  }
  .social-links-design {
    color: white;
    text-decoration: none;
    font-family: 'medium';
    font-size: .77rem;
  }
  
}

